.date-form-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2% 4%;
  box-sizing: border-box;
}

.date-form-navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #3f51b5;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
}

.date-form-navbar .date-form-nav-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.date-form-navbar .date-form-nav-title {
  font-size: 22px;
  font-weight: bold;
}

.date-form {
  width: 100%;
  max-width: 500px;
  background-color: #f9f9f9;
  padding: 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  box-sizing: border-box;
}

.date-form h3 {
  margin-bottom: 20px;
  font-size: 20px;
  color: #333;
  text-align: center;
}

.date-form p {
  margin-bottom: 15px;
  font-size: 18px;
  color: #555;
  text-align: center;
}

.date-form .form-field {
  margin-bottom: 15px;
  font-size: 18px;
  color: #555;
}

.date-form .form-label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.date-form .form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.date-form .add-button {
  width: 100%;
  padding: 10px;
  background-color: #3f51b5;
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.date-form .add-button:hover {
  background-color: #303f9f;
}

@media (max-width: 768px) {
  .date-form-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0; /* Remove padding to ensure full screen use */
  }

  .date-form-navbar {
    padding: 8px;
    border-radius: 0; /* Remove rounded corners for a cleaner mobile look */
  }

  .date-form-navbar .date-form-nav-title {
    font-size: 20px;
  }

  .date-form {
    padding: 15px;
    width: 90%; /* Use a percentage width for responsiveness */
    max-width: none; /* Remove max-width to avoid constraint */
  }

  .date-form h3 {
    font-size: 18px;
  }

  .date-form p,
  .date-form .form-field {
    font-size: 16px;
  }

  .date-form .form-input,
  .date-form .add-button {
    font-size: 16px;
    padding: 8px;
  }
}
