/* SignupPage.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
}
.otp-message {
  margin-top: 10px;
  font-size: 14px;
}

.otp-message.success {
  color: green;
}

.otp-message.error {
  color: red;
}

.signup-box {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
  text-align: center;
  transition: all 0.3s ease;
}

.signup-box h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px; /* Rounded corners */
  box-sizing: border-box;
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s; /* Added transition for box-shadow */
}

.form-group input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25); /* Box shadow on focus */
}

.otp-group {
  margin-bottom: 1rem;
  text-align: left;
}

.otp-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.otp-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.otp-inputs input {
  width: 22%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px; /* Rounded corners */
  text-align: center;
  font-size: 1.25rem;
  margin-right: 8px; /* Gap between OTP boxes */
  transition: border-color 0.3s, box-shadow 0.3s; /* Added transition for box-shadow */
}

.otp-inputs input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25); /* Box shadow on focus */
}

.resend-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 0.875rem;
  text-align: left;
  display: block;
  margin: 0.5rem 0 1rem 0; /* Adjusted spacing */
}

.verify-btn {
  width: 100%;
  padding: 0.75rem;
  background-color: #28a745;
  border: none;
  color: white;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 0.5rem; /* Reduced gap from the next button */
}

.verify-btn:hover {
  background-color: #218838;
}

.next-btn {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-btn:hover {
  background-color: #0056b3;
}
