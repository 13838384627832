/* Base Styles */
.dashboard {
  display: flex;
  height: 100vh;
  position: relative;
}

/* Custom iOS-style Calendar */
.ios-calendar {
  border: none;
  width: 100%;
  height: auto;
  background-color: #f0f0f0; /* Lighter background */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.ios-calendar .react-calendar__tile {
  border-radius: 10px; /* Slightly more rounded corners */
  height: 45px; /* Adjusted height */
}

.ios-calendar .react-calendar__tile--active {
  background: #007aff;
  color: white;
}

.ios-calendar .react-calendar__navigation button {
  color: #007aff;
  font-size: 16px;
  margin: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.friend-list-panel {
  background-color: #5a0f7d; /* Darker purple */
  color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 240px; /* Slightly reduced width */
  overflow-y: auto;
  transition: width 0.3s ease-in-out;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-title {
  font-size: 20px;
  font-weight: bold;
  margin-right: 8px;
}

.down-arrow {
  font-size: 16px;
  cursor: pointer;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.write-notes-icon {
  font-size: 20px;
  cursor: pointer;
}

.direct-messages {
  cursor: pointer;
  padding: 10px 0;
  color: #e0e0e0; /* Softer grey */
  margin-top: 15px;
  font-weight: bold;
}

.toggle-icon {
  float: right;
}

.friend-list {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.friend-item {
  cursor: pointer;
  padding: 10px;
  padding-left: 15px; /* Better alignment */
  transition: background-color 0.3s;
  border-radius: 8px; /* Rounded items */
}

.friend-item:hover {
  background-color: #4a0b6a;
}

.add-friend {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.add-friend-input {
  width: calc(100% - 40px); /* Adjusted for spacing */
  padding: 8px;
  border-radius: 8px; /* More rounded */
  border: 1px solid #ccc; /* Added border */
}

.add-friend-btn {
  padding: 8px 10px;
  border-radius: 8px; /* Rounded button */
  border: none;
  margin-left: 5px;
  cursor: pointer;
  background-color: #4a0b6a; /* Darker purple */
  color: white;
}

.chatbox-container {
  background-color: #ececec; /* Lighter background */
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.chatbox {
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Slightly larger shadow */
  border-radius: 12px; /* More rounded corners */
}

.chatbox-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  background-color: #007aff;
  color: white;
  text-align: center;
  padding: 12px; /* Slightly thicker padding */
  border-radius: 8px 8px 0 0; /* Rounded top corners */
}

.chatbox-body {
  flex-grow: 1;
  padding: 10px 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chatbox-footer {
  display: flex;
  padding: 12px; /* Slightly thicker padding */
  background-color: #f3f3f3;
  border-top: 1px solid #ccc; /* Separator line */
  border-radius: 0 0 8px 8px; /* Rounded bottom corners */
}

.chatbox-input {
  width: calc(100% - 80px); /* Adjusted for spacing */
  padding: 10px;
  border-radius: 8px; /* Rounded input */
  border: 1px solid #ccc;
}

.chatbox-send-btn {
  padding: 10px;
  margin-left: 10px;
  border-radius: 8px; /* Rounded button */
  border: none;
  background-color: #007aff;
  color: white;
  cursor: pointer;
}

.chatbox-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 20px;
}

.chat-message {
  display: flex;
  margin-bottom: 10px;
}

.chat-message-user {
  font-weight: bold;
  margin-right: 10px;
}

.infinite-calendar {
  width: 99%;
  height: 70vh;
}

.calendar-component {
  /* Add any specific styles for the calendar component if needed */
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .friend-list-panel {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: translateX(-100%); /* Hide off-screen initially */
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    overflow: hidden; /* Prevent overflow */
  }

  .friend-list-panel.mobile-visible {
    transform: translateX(0); /* Slide in on mobile when visible */
  }

  .mobile-navbar {
    display: flex;
    background-color: #5a0f7d; /* Darker purple */
    color: white;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }

  .chatbox-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px; /* Push down to avoid mobile navbar overlap */
    overflow: hidden; /* Prevent overflow */
  }

  .chatbox {
    width: 100%;
    max-width: 100%;
    height: calc(100% - 60px); /* Adjust for navbar height */
  }

  .chatbox-body {
    padding: 5px;
  }

  .infinite-calendar {
    max-height: calc(
      100% - 50px
    ); /* Leave space for mobile header and footer */
  }
}

@media screen and (min-width: 769px) {
  .mobile-navbar {
    display: none; /* Hide mobile navbar on desktop */
  }

  .chatbox-body {
    width: 100%;
    max-width: 100%;
  }
}
